// AUTHOR - KHYATI RADADIYA
// COMPANY NAME - RADIX ANALYTICS
// Version - 1
// Change log
//   ---------------------------------------------------
//   Date         Author   Comment
//   ---------------------------------------------------
//   2024-06-10	  Khyati   This is index file of project

import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primeicons/primeicons.css";

import store from "./redux/store";
import App from "./App";

import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <PrimeReactProvider>
    <Provider store={store}>
      <ToastContainer autoClose={5000} />

      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </PrimeReactProvider>
);

reportWebVitals();
